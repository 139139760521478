<template>
  <div class="col col4">
    <el-form class="content bascform" ref="formtemp" :model="form" label-width="125px">
      <el-form-item label="选择模板" label-width="125px">
        <el-select v-model="templateId" filterable   placeholder="请选择模板" @change="changechoice" >
          <el-option v-for=" item in tempdata.templateList" :key="item" :label="item.templateName" :value="item.templateId"></el-option>
        </el-select>
      </el-form-item>
      <div>
        <el-form-item label="合同要素"  v-show="contractelementShow" >
          <div class="contractfactor">
            <div class="col1" :key="index" v-for="(item,index) in fieldList">
              <el-form-item :label="item.labelName" label-width="225px">
                <el-input v-model="contractList[item.keyword]" v-if="item.inputType == 1"></el-input>
                <el-select v-model="contractList[item.keyword]"  v-if="item.inputType == 2">
                  <el-option v-for="item in item.dictList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <el-radio v-model="contractList[item.keyword]"  v-show="item.inputType == 3" v-for="item in item.dictList" :key="item.value" :label="item.label" :value="item.value"></el-radio>
                <el-checkbox-group v-model="contractList[item.keyword]" v-if="item.inputType == 4">
                  <el-checkbox v-for="item in item.dictList" :key="item.value" :label="item.label" ></el-checkbox>
                </el-checkbox-group>
                <el-input type="textarea" v-model="contractList[item.keyword]" v-if="item.inputType == 6"></el-input>
                <el-date-picker v-if="item.dataType == 3" type="date" placeholder="选择日期" v-model="contractList[item.keyword]"
                  style="width: 100%;"></el-date-picker>
              </el-form-item>
            </div>
          </div>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { requestForm } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  props: ['formData'],
  name: 'DraftaContractdialog',
  data () {
    return {
      contractelementShow: false,
      form: {},
      tempdata: {
        templateList: []
      },
      contractList: {},
      templateId: '',
      fieldList: [],
      keyword: '',
      inputType: '',
      dataType: ''
    }
  },

  mounted () {
    this.choiceTemplate()
  },
  methods: {
    //  合同模板
    choiceTemplate () {
      requestForm('/api/pact/inquiryTemplate/queryAll', 'post').then((response) => {
        if (response.code === '200') {
          this.tempdata.templateList = response.data
          for (var item of this.tempdata.templateList) {
            var tempList = {}
            tempList.label = item.templateName
          }
        }
      })
    },
    changechoice () {
      this.contractelementShow = true
      requestForm('/api/pact/inquiryEssentialFactor/queryOne', 'POST', { id: this.templateId }).then((response) => {
        if (response.code === '200') {
          this.fieldList = response.data
          this.fieldList.forEach(item => {
            this.keyword = item.keyword
            this.dataType = item.dataType
            if (this.keyword === 'jiafang') {
              this.contractList[item.keyword] = this.formData.mySubjectCode.substring(5)
            }
            if (this.keyword === 'yifang') {
              this.contractList[item.keyword] = this.formData.subject
            }
            if (this.dataType === '3') { // dataType 的类型为 3 时间类型,格式化时间
              this.contractList[this.keyword] = dayjs(this.contractList[this.keyword]).format('YYYY-MM-DD')
              console.log('111', this.contractList[this.keyword])
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/css/elform.scss';
 .contractfactor{
    width:100%;
    height: 100%;
    border: 1px dashed #ccc;
    .col1{
      padding: 10px 10px 5px 0px;
    }
  }
</style>
